.pulseContainer {
  position: relative;
  top: -32px;
  left: -24px;
  pointer-events: none !important;
}

.marker,
.marker:before,
.marker:after {
  display: block;
  top: 16px;
  left: 12px;
  width: 24px;
  height: 24px;
  position: relative;
  border-radius: 50%;
}
.marker:before,
.marker:after {
  position: absolute;
  opacity: 0;
  border: 10px solid red;
  animation: pulse 3s infinite ease-out;
}
.marker:before {
  content: "";
  animation-delay: 0s;
}
.marker:after {
  content: "";
  animation-delay: 1.5s;
}

@keyframes pulse {
  from {
    transform: scale(0.6);
    opacity: 0.8;
  }
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}
