@import "normalize.css";
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

p {
  margin-bottom: 0;
}

.duration-button:not([class*="bp5-intent-"]) {
  background-color: #2c3a48;
  color: #fff;
  font-size: 10px;
  font-family: "Inter";
}

.duration-button:not([class*="bp5-intent-"]):hover {
  background-color: #2c3a48;
}

.duration-button:not([class*="bp5-intent-"]).bp5-active {
  background-color: #2c3a48;
}

.duration-button:not([class*="bp5-intent-"]).bp5-disabled {
  background-color: #2c3a48;
}

.bp5-menu {
  background-color: #2c3a48;
  color: white;
}

.bp5-menu-item.bp5-active {
  color: #19b2ab;
}

.bp5-divider {
  border-color: white;
}

.bp5-popover .bp5-popover-content {
  border-radius: 0;
  background: none;
}
.bp5-popover-transition-container {
  z-index: 999;
}

.bp5-datepicker-month-select {
  width: 82px;
}

.bp5-html-select.bp5-minimal.bp5-datepicker-month-select > span {
  right: 2px !important;
}

.bp5-datepicker {
  background-color: #2c3a48;
  color: white;
  /* border-radius: 10px 10px 0 0; */
}

.bp5-datepicker .DayPicker-Day.DayPicker-Day--disabled {
  background: none;
  color: black;
  cursor: not-allowed;
}

.bp5-datepicker .DayPicker-Day.DayPicker-Day--disabled:hover {
  color: black;
  background: none;
}

.bp5-datepicker .DayPicker-Day.DayPicker-Day--disabled:focus {
  background: none;
  color: black;
}

.bp5-html-select.bp5-minimal > select {
  color: white;
}

.bp5-html-select.bp5-minimal > select:hover {
  background: none;
  color: white;
}

.bp5-button .bp5-icon {
  color: white;
}
.bp5-button-text {
  user-select: none;
}

.bp5-html-select .bp5-icon {
  color: white;
}

.bp5-datepicker .DayPicker-Day.DayPicker-Day--outside {
  color: rgba(95, 107, 124, 0.6);
}
.bp5-datepicker .DayPicker-Day.DayPicker-Day--isToday .bp5-datepicker-day-wrapper {
  border: 0px solid rgba(17, 20, 24, 0.15);
}
.bp5-datepicker .DayPicker-Day:hover,
.bp5-datepicker .DayPicker-Day:focus {
  background: #19b2ab;
  color: white;
}
.bp5-datepicker .DayPicker-Day:active {
  background: #19b2ab;
}
.bp5-datepicker .DayPicker-Day.DayPicker-Day--selected {
  background-color: #19b2ab;
  border-radius: 2px;
  color: #ffffff;
}

.bp5-datepicker .DayPicker-Day.DayPicker-Day--selected:hover {
  background-color: #19b2ab;
}
.bp5-datepicker .DayPicker-Day.DayPicker-Day--selected:active {
  background-color: #19b2ab;
}

.bp5-daterangepicker .DayPicker-Day--hovered-range {
  border-radius: 0;
  color: white;
}
.bp5-daterangepicker
  .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(
    .DayPicker-Day--selected-range
  ):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
  background-color: #19b2ab;
}
.bp5-daterangepicker .DayPicker-Day--selected-range {
  background-color: #19b2ab;
  border-radius: 0;
  color: white;
}
.bp5-daterangepicker .DayPicker-Day--selected-range:hover {
  background-color: #19b2ab;
  color: white;
}
.bp5-timepicker .bp5-timepicker-input-row {
  background-color: #2c3a48;
  box-shadow: none;
  user-select: none;
}
.bp5-timepicker .bp5-timepicker-input-row input {
  color: #fff;
}
.bp5-timepicker .bp5-timepicker-divider-text {
  color: #fff;
}
.bp5-timepicker .bp5-timepicker-arrow-button {
  color: #fff;
}
.bp5-timepicker .bp5-timepicker-arrow-button:hover {
  color: #fff;
}
*:focus {
  outline: none;
}

.max-width {
  width: 100%;
}