.bp5-datePicker {
  border-radius: 10px;
}

.bp5-timepicker {
}

.bp5-timepicker .bp5-timepicker-divider-text {
  visibility: hidden;
}

.bp5-timepicker .bp5-timepicker-arrow-button {
  padding: 0;
}

.bp5-timepicker .bp5-timepicker-arrow-row {
  height: 0;
}

.bp5-timepicker
  .bp5-timepicker-arrow-row
  .bp5-timepicker-arrow-button.bp5-timepicker-hour
  .bp5-icon.bp5-icon-chevron-up {
  position: relative;
  bottom: 2px;
  left: 24px;
}

.bp5-timepicker
  .bp5-timepicker-arrow-row
  .bp5-timepicker-arrow-button.bp5-timepicker-minute
  .bp5-icon.bp5-icon-chevron-up {
  position: relative;
  bottom: 2px;
  left: 42px;
}

.bp5-timepicker
  .bp5-timepicker-arrow-row
  .bp5-timepicker-arrow-button.bp5-timepicker-hour
  .bp5-icon.bp5-icon-chevron-down {
  position: relative;
  bottom: 16px;
  left: 24px;
}

.bp5-timepicker
  .bp5-timepicker-arrow-row
  .bp5-timepicker-arrow-button.bp5-timepicker-minute
  .bp5-icon.bp5-icon-chevron-down {
  position: relative;
  bottom: 16px;
  left: 42px;
}

.bp5-timepicker .bp5-timepicker-input-row input {
  border: solid 1px white;
  width: 50px;
  padding: 5px;
  text-align: left;
  /* height: 35px; */
}

.bp5-popover .bp5-popover-content {
  border-radius: 10px;
  background-color: "none";
}
